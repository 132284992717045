<template>
  <b-container fluid>
      <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Großhändler</h4>
                            <p>Liste aller Großhändler</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="mr-1 btn btn-default" @click="loadWholesalers">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('wholesalers.create')">
                                <i class="fas fa-plus-circle"></i> Neuer Großhändler
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                        <table class="table table-hover table-sm">
                            <thead>
                                <th><input type="checkbox" v-model="selectAll" /></th>
                                <th>Nr.</th>
                                <th>
                                    <a href="#" @click.prevent="changeSort('name')">Name</a>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                    <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                </th>
                                <th>
                                    Anschrift
                                </th>
                                <th>Kundennummer</th>
                                <th style="width: 140px;">Aktionen</th>                 
                            </thead>
                            <tbody>
                                <tr v-for="(wholesaler) in wholesalers.data" :key="wholesaler.id">
                                    <td><input type="checkbox" :value="wholesaler.id" v-model="selectedWholesalers"/></td>
                                    <td>{{ wholesaler.wholesalernumber }}</td>
                                    <td>{{ wholesaler.name }}</td>
                                    <td>
                                        {{ wholesaler.street }} {{ wholesaler.house_number }}
                                        <br>
                                        {{ wholesaler.zipcode }} {{ wholesaler.city }}
                                    </td>
                                    <td>
                                        {{ wholesaler.customernumber }}
                                    </td>
                                    <td>
                                        <router-link class="mr-1 btn btn-info btn-xs" :to="{name: 'wholesaler-orders.create', params: {id: wholesaler.id}}" v-if="$auth.check('wholesalers.edit')"><i class="fas fa-fw fa-truck"></i></router-link>

                                        <button type="button" class="mr-1 btn btn-secondary btn-xs" @click="downloadIngredientsAsPdf(wholesaler)">
                                            <i class="fas fa-fw fa-download"></i>
                                        </button>
                                        <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'wholesalers.details', params: {id: wholesaler.id}}" v-if="$auth.check('wholesalers.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                        <b-button size="xs" @click="deleteWholesaler(wholesaler.id)" variant="danger" v-if="$auth.check('wholesalers.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="clearfix align-middle card-footer">
                        <pagination class="float-left" :data="wholesalers" @pagination-change-page="loadWholesalers" :limit="3"></pagination>
                        
                        <select class="float-right form-control-sm" v-model="params.per_page">
                            <option value="25">25 Artikel</option>
                            <option value="50">50 Artikel</option>
                            <option value="75">75 Artikel</option>
                            <option value="100">100 Artikel</option>
                            <option value="125">125 Artikel</option>
                            <option value="150">150 Artikel</option>
                        </select>
                        <p v-if="notEmptyObject(wholesalers)" class="float-right mr-2">Anzeige Eintrag {{ wholesalers.meta.from }} - {{ wholesalers.meta.to }} von {{ wholesalers.meta.total }}</p>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="wholesalerModal" title="Erstelle ein neuen Großhändler" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-12">

                        <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}" :disabled="!editMode">
                                <has-error :form="form" field="name"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="send_email" :disabled="!editMode">
                                    <label class="custom-control-label" for="customSwitch1">Bestellung per Mail</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email" class="control-label">E-Mail*</label>
                                <input v-model="form.email" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('email')}" :disabled="form.send_email == 0 || !editMode">
                                <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="send_phone" :disabled="!editMode">
                                    <label class="custom-control-label" for="customSwitch2">Bestellung per Telefon</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email" class="control-label">Telefon*</label>
                                <input v-model="form.phone" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('phone')}" :disabled="form.send_phone == 0 || !editMode">
                                <has-error :form="form" field="phone"></has-error>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="send_web" :disabled="!editMode">
                                    <label class="custom-control-label" for="customSwitch3">Bestellung per Webshop</label>
                                </div>
                            </div>
                            <div class="form-group" >
                                <label for="url" class="control-label">URL*</label>
                                <input v-model="form.url" type="email" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('url')}" :disabled="form.send_web == 0 || !editMode">
                                <has-error :form="form" field="url"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="street" class="control-label">Straße*</label>
                                <input v-model="form.street" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street')}" :disabled="!editMode">
                                <has-error :form="form" field="street"></has-error>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="house_number" class="control-label">Nr.*</label>
                                <input v-model="form.house_number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number')}" :disabled="!editMode">
                                <has-error :form="form" field="house_number"></has-error>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <label for="zipcode" class="control-label">PLZ*</label>
                            <input v-model="form.zipcode" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode')}" :disabled="!editMode">
                            <has-error :form="form" field="zipcode"></has-error>
                        </div>

                        <div class="col-md-3">
                            <label for="city" class="control-label">Ort*</label>
                            <input v-model="form.city" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city')}" :disabled="!editMode">
                            <has-error :form="form" field="city"></has-error>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-4">
                            <label for="customernumber" class="control-label">Kunden-Nr.</label>
                            <input v-model="form.customernumber" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('customernumber')}" :disabled="!editMode">
                            <has-error :form="form" field="customernumber"></has-error>
                        </div>

                        <div class="col-md-4">
                            <label for="skonto" class="control-label">Skonto</label>
                            <input v-model="form.skonto" type="number" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('skonto')}" :disabled="!editMode">
                            <has-error :form="form" field="skonto"></has-error>
                        </div>

                        <div class="col-md-4">
                            <label for="min_order_amount" class="control-label">Mindestbestellwert</label>
                            <input v-model="form.min_order_amount" type="number" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('min_order_amount')}" :disabled="!editMode">
                            <has-error :form="form" field="min_order_amount"></has-error>
                        </div>
                    </div>



                        <div class="form-group">
                            <label for="name" class="control-label">Notizen</label>
                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.notice" :disabled="!editMode"></ckeditor>
                            <has-error :form="form" field="notice"></has-error>
                        </div>

                    </div>
                </div>
            </form>
        </b-modal>


  </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';
import {mapState} from 'vuex';

export default {
    name: "Wholesalers",
    title: "Großhändler",
    data() {
        return {
            form: new window.Form({
                 id: "",
                name: "",
                url: "",
                email: "",
                order_email: "",
                phone: "",
                notice: "",
                orderinfo: "",
                min_order_amount: "",
                skonto: "",
                send_email: 0,
                send_phone: 0,
                send_web: 0,
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                customernumber: "",
            }),
            send_email: 0,
            send_phone: 0,
            send_web: 0,
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            editMode: true,
            wholesalers: {},
            selectedWholesalers: [],
        }
    },

    watch: {
        params: {
            handler () {
                this.loadWholesalers();
            },
            deep: true
        },

        send_email: function(){
            //this.form.email = "";
            if(this.send_email == true)
            {
                this.form.send_email = 1;
            }
            else
            {
                this.form.send_email = 0;
            }
        },

        send_phone: function(){
            //this.form.phone = "";
            if(this.send_phone == true)
            {
                this.form.send_phone = 1;
            }
            else
            {
                this.form.send_phone = 0;
            }
        },

        send_web: function(){
            //this.form.web = "";
            if(this.send_web == true)
            {
                this.form.send_web = 1;
            }
            else
            {
                this.form.send_web = 0;
            }
        },
    },

    computed: {
        selectAll: {
            get: function () {
                return this.wholesalers.data ? this.selectedWholesalers.length == this.wholesalers.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.wholesalers.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedWholesalers = selected;
            }
        },
        ...mapState('wholesalers', ['params', 'search', 'page'])
    },


    methods: {

        downloadIngredientsAsPdf(wholesaler){
            this.axios
                .get("/wholesalers/" + wholesaler.id + '/export/ingredients/pdf',{
                    responseType: 'blob',
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", wholesaler.name + '_Waren.pdf');
                    document.body.appendChild(link);

                    // Dynamicall click the a link element
                    // This will download the csv file
                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createWholesaler();     
        },

        createModal(){
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("wholesalerModal");
        },

        createWholesaler(){
            this.$Progress.start();
            this.form
                .post("/wholesalers")
                .then(() => {
                    this.loadWholesalers();
                    this.$bvModal.hide("wholesalerModal");
                    this.$swal({
                        icon: "success",
                        title: "Großhändler gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },
        

        deleteWholesaler(id){
          this.$swal({
                    title: "Möchtest du den Großhändler wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/wholesalers/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Großhändler erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadWholesalers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        loadWholesalers(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/wholesalers",{
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.wholesalers = response.data;
                    this.$store.commit('wholesalers/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.loadWholesalers();
    },
}
</script>

<style>

</style>